.fixTableHead {
    display: grid;
    overflow-y: auto;
    height: 110px;
  }
  .fixTableHead thead th {
    position: sticky;
    top: 0;
  }
  table {
    border-collapse: collapse;        
    width: 100%;
  }
  
  td {
    text-align: center;
    padding: 8px 15px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    
    /*border: 2px solid #529432;*/
  }
  th {
    text-align: center;
    background: #ABDD93;
    max-width: 500px;
  }

  .thumb {
    width: 10px;
    height: 10px;
  }
  .thumb:hover {
    -webkit-transform: scale(1.15, 1.15);
    transform: scale(1.15, 1.15);
  }
  
  .thumb:hover::after {
      opacity: 1;
  }