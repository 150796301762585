.td {
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
}

.td {
    position: relative;
    height: 50px;
    width: auto;
}

.sup {
    display: block;
    position: relative;
    width: 50%;
    float: left;
    padding-bottom: 15%;
    line-height: 100%;
    text-align: center;
    
}

.inf {
    display: block;
    position: relative;
    width: 50%;
    float: left;
    padding-top: 15%;
    line-height: 100%;
    text-align: center;
    
}

.diagonal-split-background{
    background-color: #013A6B;
    background-image: -webkit-linear-gradient(110deg, white 50%, rgb(207, 207, 207) 50%);
  }

.diagonal-footer-split-background{
    background-color: #013A6B;
    background-image: -webkit-linear-gradient(110deg, rgb(244, 218, 71) 50%, rgb(190, 118, 41) 50%);
  }