.flex-container {
  display: flex;
flex-direction: row;
  flex-wrap: nowrap;
}
.flex-container-item{
  /* The image used */
  background-image:url('real_esrgan_apna.jpg');
  background-position: center; 
background-repeat: no-repeat;
background-size:cover; 
width:100%;
height: 100vh;
text-align: center;
line-height: 75px;
font-size: 30px;
}

.flex-container-child {
  width: 100px;
  margin: 10px;
  text-align: center;
}

.tool{
  display: flex;
flex-direction: column;
  flex-wrap: wrap;
}