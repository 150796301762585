.flex-container {
    /* We first create a flex layout context */
    display: flex;
    
    /* Then we define the flow direction 
       and if we allow the items to wrap 
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-flow: row wrap;
    
    /* Then we define how is distributed the remaining space */
    justify-content: space-around;
    
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .flex-item {
    padding: 5px;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
  }


