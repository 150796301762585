
  .glow {
    width: auto;
    height: auto;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .glow:hover {
    -webkit-transform: scale(1.04, 1.04);
    transform: scale(1.04, 1.04);
  }
  
  .glow:hover::after {
      opacity: 1;
  }